import React, { useState, useEffect, useRef } from 'react'
import styles from './Stories.module.scss'
import 'intersection-observer'
import { Link } from 'gatsby'

const Stories = ({ posts }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <h3>Stories</h3>
        <div className={styles.grid}>
          {posts.map(post => (
            <Link to={post.fields.slug}>
              <div className={styles.story}>
                <img src={post.frontmatter.featuredImage} />
                <div className={styles.storyDetails}>
                  <h2>{post.frontmatter.title}</h2>
                  <p>{post.frontmatter.excerpt}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Stories
