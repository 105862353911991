import React, { useEffect } from 'react'
import PageHeader from '../components/stories/PageHeader'
import Nav from '../components/common/Nav'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import Stories from '../components/stories/Stories'

const HomePage = ({ data }) => {
  const posts = data.posts.nodes
  return (
    <Layout>
      <Nav />

      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <Stories posts={posts} />
        </Fade>
      </main>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/stories/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          excerpt
        }
        fields {
          slug
        }
      }
    }
  }
`
